import { GeneralApiResponse } from "src/repositories/api/Utils/ResponseUtility";
import { authApi } from "src/repositories/api/auth/Auth.api";
import TokenInfo, {
  readTokenInfo,
} from "src/repositories/api/auth/model/TokenInfo";
import localStorageService from "src/repositories/local-storage/LocalStorageService";
import {
  createFailedResponse,
  createSuccessResponse,
  FailedResponse,
} from "src/repositories/model/FailableResponse";
import {
  activeManagedUserSlice,
  inactiveManagedUserSlice,
} from "src/services/redux/slices/ManagedUserListSlice";
import tokenUnauthorizedSlice from "src/services/redux/slices/miscellaneous/TokenUnauthorizedSlice";
import { handleGeneralErrorResponse } from "src/services/utils/Utils.service";
import { reduxStore } from "../../redux/ReduxStore";
import deviceListSlice from "../../redux/slices/DeviceListSlice";
import {
  groupListSlice,
  selectedGroupSlice,
} from "../../redux/slices/GroupSlice";
import tokenSlice from "../../redux/slices/UserSlice";

async function login(
  email: string,
  password: string
): Promise<
  GeneralApiResponse<TokenInfo> | FailedResponse<"invalid-credentials">
> {
  const result = await authApi.login(email, password);
  if (result.success) {
    const tokenInfo: TokenInfo = readTokenInfo(result.value.token);
    localStorageService.storeData("tokenInfo", tokenInfo);
    reduxStore.dispatch(tokenSlice.actions.update(tokenInfo));
    // //fire and forget
    // groupService.getGroupList();
    return createSuccessResponse(tokenInfo);
  }

  return result;
}

function logout() {
  logoutInternal();
}

async function signUp(
  email: string,
  name: string,
  password: string
): Promise<
  | GeneralApiResponse
  | FailedResponse<"email-exists" | "invalid-email" | "invalid-password">
> {
  const result = await authApi.signUp(email, name, password);
  if (result.success) {
    const tokenInfo: TokenInfo = readTokenInfo(result.value.token);
    localStorageService.storeData("tokenInfo", tokenInfo);
    reduxStore.dispatch(tokenSlice.actions.update(tokenInfo));
    return createSuccessResponse();
  }
  return result;
}

async function activateAccount(
  activationCode: number
): Promise<GeneralApiResponse | FailedResponse<"incorrect-activation-code">> {
  const tokenInfo = reduxStore.getState().token;
  if (tokenInfo?.payload.isAccountActivated != false) {
    return createFailedResponse("error");
  }

  const result = await authApi.activateAccount(activationCode, tokenInfo.token);
  if (result.success) {
    const newTokenInfo = readTokenInfo(result.value.token);
    localStorageService.storeData("tokenInfo", newTokenInfo);
    reduxStore.dispatch(tokenSlice.actions.update(newTokenInfo));
    return createSuccessResponse();
  }
  if (result.code == "unauthorized") {
    if (reduxStore.getState().token != null) {
      reduxStore.dispatch(tokenSlice.actions.update(null));
      reduxStore.dispatch(tokenUnauthorizedSlice.actions.update(true));
    }
  }
  return result;
}

async function deleteAccount(): Promise<GeneralApiResponse> {
  const result = await authApi.deleteAccount();
  if (result.success) {
    logoutInternal();
    return createSuccessResponse();
  }
  handleGeneralErrorResponse(result);
  return result;
}

function changePassword(
  oldPassword: string,
  newPassword: string
): Promise<GeneralApiResponse | FailedResponse<"wrong-password">> {
  return authApi.changePassword(oldPassword, newPassword);
}

function forgetPassword(
  email: string
): Promise<
  GeneralApiResponse | FailedResponse<"invalid-email" | "user-not-found">
> {
  return authApi.forgetPassword(email);
}

function changePasswordWithCode(
  email: string,
  code: number,
  newPassword: string
): Promise<
  | GeneralApiResponse
  | FailedResponse<
      | "invalid-email"
      | "user-not-found"
      | "wrong-reset-password-code"
      | "invalid-password"
    >
> {
  return authApi.setNewPasswordWithCode(email, code, newPassword);
}

const authService = {
  login,
  signUp,
  activateAccount,
  logout,
  deleteAccount,
  changePassword,
  forgetPassword,
  changePasswordWithCode,
};

const logoutInternal = () => {
  localStorageService.removeData("tokenInfo");
  reduxStore.dispatch(tokenSlice.actions.update(null));
  reduxStore.dispatch(selectedGroupSlice.actions.update(null));
  reduxStore.dispatch(groupListSlice.actions.update(null));
  reduxStore.dispatch(activeManagedUserSlice.actions.update(null));
  reduxStore.dispatch(inactiveManagedUserSlice.actions.update(null));
  reduxStore.dispatch(deviceListSlice.actions.update(null));
};

export default authService;
