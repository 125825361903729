import {
  Anchor,
  Button,
  Checkbox,
  Container,
  MantineProvider,
  Modal,
  Paper,
  PasswordInput,
  ScrollArea,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { paths } from "src/App";
import authService from "src/services/core/auth/Auth.service";
import ChildrensPrivacyStatement from "./user-policy/ChildrensPrivacyStatement";
import PrivacyPolicy from "./user-policy/PrivacyPolicy";
import UserAgreement from "./user-policy/UserAgreements";
import useIsMobile from "src/pages/utils/isMobileHook";
import { emailRegex, getPasswordError } from "src/pages/utils/ValidatorUtils";
import {
  nullToastUpdateOptions,
  toastCommonError,
} from "src/pages/utils/ToastUtils";
import PasswordCriteriaHover from "src/pages/components/PasswordCriteriaHover";

export function SignUp() {
  const usedEmail = useRef<string>();

  const navigate = useNavigate();
  const { t, i18n } = useTranslation(undefined, { keyPrefix: "auth.signUp" });

  // User agreement
  const [uaOpened, { open: openUa, close: closeUa }] = useDisclosure(false);
  // Privacy policy
  const [ppOpened, { open: openPp, close: closePp }] = useDisclosure(false);
  // Child privacy statement
  const [cpsOpened, { open: openCps, close: closeCps }] = useDisclosure(false);
  const [userPolicyAgreed, { toggle: togglePolicyAgreed }] =
    useDisclosure(false);
  const [isMobile] = useIsMobile();

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      passwordReenter: "",
      name: "",
    },
    validate: {
      email: (value) =>
        usedEmail.current != null && value == usedEmail.current
          ? t("emailError.used")
          : emailRegex.test(value)
          ? null
          : t("emailError.invalid"),
      password: (value) => getPasswordError(value),
      passwordReenter: (value, values) =>
        value != values.password ? t("passwordReenterNotMatch") : null,
      name: (value) => (value.length > 100 ? t("nameTooLong") : null),
    },
    clearInputErrorOnChange: true,
  });

  const formSubmit = useCallback(
    async (values: typeof form.values) => {
      if (form.errors.email != null) {
        return;
      }

      const promise = authService.signUp(
        values.email,
        values.name,
        values.password
      );
      const signUpId = toast.loading(t("signingUp"), {
        autoClose: false,
        closeButton: true,
        closeOnClick: true,
      });
      const result = await promise;
      if (result.success) {
        toast.update(signUpId, {
          ...nullToastUpdateOptions,
          render: t("signUpSuccess"),
          type: "success",
        });
        navigate(paths.authPaths.activateAccount);
        return;
      }

      if (result.code == "email-exists") {
        toast.update(signUpId, {
          ...nullToastUpdateOptions,
          render: t("emailError.used"),
          type: "warning",
        });
        usedEmail.current = form.values.email;
        form.setFieldError("email", t("emailError.used"));
        return;
      }

      if (result.code == "invalid-email") {
        toast.update(signUpId, {
          ...nullToastUpdateOptions,
          render: t("invalidEmailToast"),
          type: "warning",
        });
        form.setFieldError("email", t("unacceptedEmail"));
        return;
      }

      toastCommonError(result.code, signUpId);
    },
    [form, i18n.language]
  );

  return (
    <ScrollArea className="w-full h-full">
      <div className="my-10">
        <Title ta="center">{t("registerAnAccount")}</Title>

        <Container size={520}>
          <Paper withBorder shadow="md" p={20} mt={60} radius="md">
            <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
              <TextInput
                label="Email"
                placeholder="Email"
                type="email"
                autoCapitalize="off"
                autoComplete="new-email"
                required
                mt="md"
                {...form.getInputProps("email")}
                onInput={() => form.setFieldError("email", null)}
              />
              <div className="flex flex-row w-full justify-between items-end relative">
                <PasswordInput
                  className="w-full"
                  label={t("password")}
                  inputWrapperOrder={["label", "input", "error", "description"]}
                  placeholder={t("yourPassword")}
                  autoComplete="new-password"
                  required
                  mt="md"
                  {...form.getInputProps("password")}
                />

                <div className=" top-5 right-0 absolute justify-center m-[0 auto] overflow-hidden h-4 w-4 rounded-md">
                  <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                    <PasswordCriteriaHover password={form.values.password} />
                  </div>
                </div>
              </div>
              <PasswordInput
                label={t("reenterPassword")}
                placeholder={t("reenterPasswordPlaceholder")}
                required
                mt="md"
                {...form.getInputProps("passwordReenter")}
              />
              <TextInput
                label={t("name")}
                placeholder={t("namePlaceholder")}
                required
                mt="md"
                {...form.getInputProps("name")}
              />
              <div className="flex mt-6">
                <MantineProvider theme={{ cursorType: "pointer" }}>
                  <Checkbox
                    required
                    className="xl cursor-pointer"
                    checked={userPolicyAgreed}
                    onChange={togglePolicyAgreed}
                  />
                  <Text size="sm" className="!ml-3">
                    {t("tacAgreement")}
                    <Anchor onClick={openUa} className="text-inherit" size="sm">
                      {t("userAgreement")}
                    </Anchor>
                    ,{" "}
                    <Anchor onClick={openPp} className="text-inherit" size="sm">
                      {t("privacyPolicy")}
                    </Anchor>{" "}
                    {t("and")}
                    <Anchor
                      onClick={openCps}
                      className="text-inherit"
                      size="sm"
                    >
                      {t("childrenPrivacyStatement")}
                    </Anchor>
                    .
                  </Text>
                </MantineProvider>
              </div>
              <Modal
                opened={uaOpened}
                onClose={closeUa}
                size="xl"
                fullScreen={isMobile}
              >
                <div className="sm:p-7">
                  <UserAgreement />
                </div>
              </Modal>
              <Modal
                opened={ppOpened}
                onClose={closePp}
                size="xl"
                fullScreen={isMobile}
              >
                <div className="sm:p-7">
                  <PrivacyPolicy />
                </div>
              </Modal>
              <Modal
                opened={cpsOpened}
                onClose={closeCps}
                size="xl"
                fullScreen={isMobile}
              >
                <div className="sm:p-7">
                  <ChildrensPrivacyStatement />
                </div>
              </Modal>
              <Button
                fullWidth
                mt="md"
                type="submit"
                disabled={!userPolicyAgreed}
              >
                {userPolicyAgreed ? t("signUp") : t("aggreeToSignUp")}
              </Button>
            </form>
            <div className="flex justify-start mt-7 text-[14px]">
              <div>
                <span>
                  {t("hasAnAccount")}
                  <Link
                    className="text-[var(--mantine-primary-color-filled)] hover:underline"
                    to={{ pathname: paths.authPaths.login }}
                  >
                    <span className="">{t("login")}</span>
                  </Link>
                </span>
              </div>
            </div>
          </Paper>
        </Container>
      </div>
    </ScrollArea>
  );
}

export default SignUp;
