import {
  Button,
  Container,
  Paper,
  ScrollArea,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import authService from "src/services/core/auth/Auth.service";
import {
  nullToastUpdateOptions,
  toastCommonError,
} from "../../utils/ToastUtils";
import ChangePasswordWithCode from "./ChangePasswordWithCode";

export function ForgetPassword() {
  const [email, setEmail] = useState<string>();
  const invalidEmail = useRef<string>();
  const notFoundEmail = useRef<string>();
  const { t } = useTranslation(undefined, { keyPrefix: "auth.forgetPassword" });

  const form = useForm({
    initialValues: {
      email: "",
    },
  });

  const formSubmit = useCallback(
    async (values: typeof form.values) => {
      const promise = authService.forgetPassword(values.email);
      const requesting = toast.loading(t("requesting"), {
        autoClose: false,
        closeButton: true,
        closeOnClick: true,
      });
      const result = await promise;
      if (result.success) {
        toast.update(requesting, {
          ...nullToastUpdateOptions,
          render: t("requestSuccess"),
          type: "success",
        });
        setEmail(values.email);

        return;
      }

      if (result.code == "invalid-email") {
        toast.update(requesting, {
          ...nullToastUpdateOptions,
          render: t("invalidEmail"),
          type: "error",
        });
        form.setFieldError("email", t("invalidEmail"));
        invalidEmail.current = form.values.email;
        return;
      }

      if (result.code == "user-not-found") {
        toast.update(requesting, {
          ...nullToastUpdateOptions,
          render: t("userNotFound"),
          type: "error",
        });
        form.setFieldError("email", t("userNotFound"));
        notFoundEmail.current = form.values.email;
        return;
      }

      console.log("result:", JSON.stringify(result));

      toastCommonError(result.code, requesting);
    },
    [form]
  );

  return email == null ? (
    <ScrollArea className="w-full h-full">
      <div className="my-10">
        <Title ta="center" mx={10}>
          {t("title")}
        </Title>

        <Container size={420}>
          <Paper withBorder shadow="md" p={20} mt={"8vh"} radius="md">
            <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
              <TextInput
                label="Email"
                placeholder="Email"
                autoCapitalize="off"
                type="email"
                autoComplete="off"
                required
                mt="md"
                {...form.getInputProps("email")}
              />
              <Button fullWidth mt="xl" type="submit">
                {t("submit")}
              </Button>
            </form>
          </Paper>
        </Container>
      </div>
    </ScrollArea>
  ) : (
    <ChangePasswordWithCode email={email} />
  );
}

export default ForgetPassword;
