import { HoverCard } from "@mantine/core";
import { IconInfoCircleFilled } from "@tabler/icons-react";
import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { validatePassword } from "../utils/ValidatorUtils";

const PasswordCriteriaHover = (values: { password: string }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "components.passwordCriteriaHover",
  });

  const passwordValidation = useMemo(
    () => validatePassword(values.password),
    [values.password]
  );
  return (
    <HoverCard width={280} shadow="md">
      <HoverCard.Target>
        <IconInfoCircleFilled
          size={27}
          color="var(--mantine-primary-color-filled)"
        />
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <div className="mt-3 ml-2">
          <span>
            {t("passwordCriteria.title")}
            <br />
            <GreenIfTrue condition={passwordValidation.isLength8}>
              {t("passwordCriteria.length8")}
            </GreenIfTrue>
            <br />
            <GreenIfTrue condition={passwordValidation.containsDigit}>
              {t("passwordCriteria.containsNumber")}
            </GreenIfTrue>
            <br />
            <GreenIfTrue condition={passwordValidation.containsUppercase}>
              {t("passwordCriteria.containsUppercase")}
            </GreenIfTrue>
            <br />
            <GreenIfTrue condition={passwordValidation.containsLowercaes}>
              {t("passwordCriteria.containsLowercase")}
            </GreenIfTrue>
          </span>
        </div>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

function GreenIfTrue(props: { condition: boolean; children: ReactNode }) {
  return (
    <span className={props.condition ? "text-green-600" : ""}>
      {props.children}&nbsp;
      {props.condition ? (
        <span>&#x2713;</span>
      ) : (
        <span className="text-red-600">X</span>
      )}
    </span>
  );
}

export default PasswordCriteriaHover;
