import {
  Button,
  Container,
  InputLabel,
  Modal,
  Paper,
  ScrollArea,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { paths } from "src/App";
import {
  nullToastUpdateOptions,
  toastCommonError,
} from "src/pages/utils/ToastUtils";
import { emailRegex } from "src/pages/utils/ValidatorUtils";
import userService from "src/services/core/user/User.service";
import { useAppSelector } from "src/services/redux/ReduxStore";
import ChangePassword from "./components/ChangePassword";

type UserProfileValues = {
  name: string;
  email: string;
};

const UserProfile: () => JSX.Element = () => {
  const usedEmail = useRef<string>();
  const tokenPayload = useAppSelector((s) => s.token)?.payload;
  const { t } = useTranslation(undefined, { keyPrefix: "inApp.userProfile" });
  const [
    changePasswordOpened,
    { close: closeChangePassword, open: openChangePassword },
  ] = useDisclosure(false);

  if (tokenPayload == null || !tokenPayload.isAccountActivated) {
    return <Navigate to={paths.authPaths.login}></Navigate>;
  }

  const form = useForm<UserProfileValues>({
    initialValues: {
      name: tokenPayload.name,
      email: tokenPayload.email,
    },
    validate: {
      email: (value) =>
        usedEmail.current != null && value == usedEmail.current
          ? t("emailUsed")
          : emailRegex.test(value)
          ? null
          : t("invalidEmail"),
    },
  });

  const formSubmit = useCallback(async (values: UserProfileValues) => {
    const promise = userService.updateAccount(values.email, values.name);
    const updateAccountId = toast.loading(t("updating"), {
      autoClose: false,
      closeButton: true,
      closeOnClick: true,
    });
    const result = await promise;
    if (result.success) {
      toast.update(updateAccountId, {
        ...nullToastUpdateOptions,
        render: t("updateSuccess"),
        type: "success",
      });

      return;
    }
    if (result.code == "email-exists") {
      toast.update(updateAccountId, {
        ...nullToastUpdateOptions,
        render: t("emailUsed"),
        type: "warning",
      });
      usedEmail.current = form.values.email;
      form.setFieldError("email", t("emailUsed"));
      return;
    }

    if (result.code == "invalid-email") {
      toast.update(updateAccountId, {
        ...nullToastUpdateOptions,
        render: t("invalidEmail"),
        type: "error",
      });
      return;
    }

    toastCommonError(result.code, updateAccountId);
  }, []);

  return (
    <ScrollArea>
      <Container>
        <Paper py={"7vh"} radius="md">
          <Title className="!mb-10 text-left">{t("userProfile")}</Title>
          <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
            <TextInput
              label="Email"
              placeholder="abc@example.com"
              type="email"
              mt="md"
              spellCheck={false}
              {...form.getInputProps("email")}
            />
            <TextInput
              label={t("nameLabel")}
              placeholder={t("namePlaceholder")}
              mt="md"
              spellCheck={false}
              {...form.getInputProps("name")}
            />
            <div className="mt-5">
              <InputLabel>{t("passwordLabel")}:</InputLabel>
              <Button
                variant="light"
                ml="sm"
                size="compact-sm"
                onClick={openChangePassword}
              >
                {t("changePasswordButton")}
              </Button>
            </div>
            <div>
              <Button mt="xl" type="submit">
                {t("update")}
              </Button>
            </div>
          </form>
        </Paper>
      </Container>
      <Modal opened={changePasswordOpened} onClose={closeChangePassword}>
        <ChangePassword onSuccess={closeChangePassword} />
      </Modal>
    </ScrollArea>
  );
};

export default UserProfile;
