import {
  Button,
  Container,
  Paper,
  PasswordInput,
  ScrollArea,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { paths } from "src/App";
import authService from "src/services/core/auth/Auth.service";
import {
  nullToastUpdateOptions,
  toastCommonError,
} from "../../utils/ToastUtils";

export function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation(undefined, { keyPrefix: "auth.login" });

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
  });

  const formSubmit = useCallback(
    async (values: typeof form.values) => {
      const promise = authService.login(values.email, values.password);
      const loginId = toast.loading(t("loggingIn"), {
        autoClose: false,
        closeButton: true,
        closeOnClick: true,
      });
      const result = await promise;
      if (result.success) {
        if (result.value.payload.isAccountActivated) {
          toast.update(loginId, {
            ...nullToastUpdateOptions,
            render: t("loginSuccessful"),
            type: "success",
          });
          navigate(paths.inAppPaths.default);
        } else {
          toast.update(loginId, {
            ...nullToastUpdateOptions,
            render: t("accountNotActivated"),
            type: "info",
            autoClose: 7000,
          });
          navigate(paths.authPaths.activateAccount);
        }

        return;
      }

      if (result.code == "invalid-credentials") {
        toast.update(loginId, {
          ...nullToastUpdateOptions,
          render: t("wrongCredentials"),
          type: "error",
        });
        return;
      }

      toastCommonError(result.code, loginId);
    },
    [form]
  );

  return (
    <ScrollArea className="w-full h-full">
      <div className="my-10">
        <Title ta="center" mx={10}>
          {t("greeting")}
        </Title>

        <Container size={420}>
          <Paper withBorder shadow="md" p={20} mt={"8vh"} radius="md">
            <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
              <TextInput
                label="Email"
                placeholder="Email"
                type="email"
                autoCapitalize="off"
                autoComplete="off"
                required
                mt="md"
                {...form.getInputProps("email")}
              />
              <PasswordInput
                label={t("password")}
                placeholder={t("password")}
                required
                mt="md"
                {...form.getInputProps("password")}
              />
              {/* <Group justify="space-between" mt="lg">
            <Checkbox label="Remember me" />
            <Anchor component="button" size="sm">
              Forgot password?
            </Anchor>
          </Group> */}
              <Button fullWidth mt="xl" type="submit">
                {t("signIn")}
              </Button>
            </form>
            <div className="flex justify-end mt-7 text-[14px]">
              <div>
                <span>
                  {t("noAccount")}{" "}
                  <Link
                    className="text-[var(--mantine-primary-color-filled)]  hover:underline"
                    to={{ pathname: paths.authPaths.signUp }}
                  >
                    {t("signUp")}
                  </Link>
                </span>
              </div>
            </div>
            <div className="flex justify-end mt-2 text-[14px]">
              <div>
                <span>
                  <Link
                    className="text-[var(--mantine-primary-color-filled)]  hover:underline"
                    to={{ pathname: paths.authPaths.forgetPassword }}
                  >
                    {t("forgotPassword")}
                  </Link>
                </span>
              </div>
            </div>
          </Paper>
        </Container>
      </div>
    </ScrollArea>
  );
}

export default Login;
