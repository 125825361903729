import {
  createFailedResponse,
  createSuccessResponse,
  FailedResponse,
} from "../../model/FailableResponse";
import TokenHolder from "../auth/model/TokenHolder";
import { globalAxiosAction } from "../Init.api";
import { createDefaultAxios } from "../Utils/AxiosUtility";
import {
  GeneralApiResponse,
  handleGeneralApiError,
} from "../Utils/ResponseUtility";
import { getBrearerTokenHeader } from "../Utils/Utils.api";

const axiosInstance = createDefaultAxios("/api/user/", 8000);
globalAxiosAction.subscribe(axiosInstance);

async function updateAccount(
  email?: string,
  name?: string
): Promise<
  | GeneralApiResponse<TokenHolder>
  | FailedResponse<"invalid-email" | "email-exists">
> {
  try {
    const response = await axiosInstance.patch<TokenHolder>(
      "updateAccount",
      {
        email,
        name,
      },
      getBrearerTokenHeader()
    );
    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e, (e, status, data) => {
      // not guaranteed
      const code = data.code as string;
      if (
        (status == 400 && code == "invalid-email") ||
        (status == 409 && code == "email-exists")
      ) {
        return createFailedResponse(code);
      }
      return null;
    });
  }
}

export const userapi = {
  updateAccount,
};
