import TokenHolder from "src/repositories/api/auth/model/TokenHolder";
import { readTokenInfo } from "src/repositories/api/auth/model/TokenInfo";
import { userapi as userApi } from "src/repositories/api/user/User.api";
import { GeneralApiResponse } from "src/repositories/api/Utils/ResponseUtility";
import localStorageService from "src/repositories/local-storage/LocalStorageService";
import { FailedResponse } from "src/repositories/model/FailableResponse";
import { reduxStore } from "src/services/redux/ReduxStore";
import tokenSlice from "src/services/redux/slices/UserSlice";

async function updateAccount(
  email?: string,
  name?: string
): Promise<
  | GeneralApiResponse<TokenHolder>
  | FailedResponse<"invalid-email" | "email-exists">
> {
  const result = await userApi.updateAccount(email, name);
  if (result.success) {
    const token = readTokenInfo(result.value.token);
    reduxStore.dispatch(tokenSlice.actions.update(token));
    localStorageService.storeData("tokenInfo", token);
  }

  return result;
}

const userService = {
  updateAccount,
};

export default userService;
