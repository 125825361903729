import {
  Button,
  Container,
  InputLabel,
  Paper,
  PasswordInput,
  PinInput,
  ScrollArea,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { paths } from "src/App";
import PasswordCriteriaHover from "src/pages/components/PasswordCriteriaHover";
import { getPasswordError } from "src/pages/utils/ValidatorUtils";
import authService from "src/services/core/auth/Auth.service";
import {
  nullToastUpdateOptions,
  toastCommonError,
} from "../../utils/ToastUtils";

export function ChangePasswordWithCode(params: { email: string }) {
  const navigate = useNavigate();
  const wrongCode = useRef<string>();
  const { t } = useTranslation(undefined, {
    keyPrefix: "auth.changePasswordWithCode",
  });

  const form = useForm({
    initialValues: {
      code: "",
      newPassword: "",
      newPasswordReenter: "",
    },
    validate: {
      code: (value) =>
        wrongCode.current != null && wrongCode.current == value
          ? t("wrongCode")
          : value.length == 8
          ? null
          : t("error.codeNotEntered"),
      newPassword: (value) => getPasswordError(value),
      newPasswordReenter: (value, values) =>
        value != values.newPassword ? t("passwordReenterNotMatch") : undefined,
    },
    transformValues: (values) => ({
      code: Number.isNaN(Number(values.code)) ? 0 : Number(values.code),
      newPassword: values.newPassword,
    }),
  });

  const formSubmit = useCallback(
    async (values: ReturnType<typeof form.getTransformedValues>) => {
      const promise = authService.changePasswordWithCode(
        params.email,
        values.code,
        values.newPassword
      );
      const requesting = toast.loading(t("requesting"), {
        autoClose: false,
        closeButton: true,
        closeOnClick: true,
      });
      const result = await promise;
      if (result.success) {
        toast.update(requesting, {
          ...nullToastUpdateOptions,
          render: t("requestSuccess"),
          type: "success",
        });
        navigate(paths.inAppPaths.default);

        return;
      }

      if (result.code == "wrong-reset-password-code") {
        toast.update(requesting, {
          ...nullToastUpdateOptions,
          render: t("wrongCode"),
          type: "error",
        });
        form.setFieldError("code", t("wrongCode"));
        wrongCode.current = values.code.toString().padStart(8, "0");
        return;
      }

      toastCommonError(result.code, requesting);
    },
    [form]
  );

  return (
    <ScrollArea className="w-full h-full">
      <div className="my-10">
        <Title ta="center" mx={10}>
          {t("title")}
        </Title>

        <Container size={420}>
          <Paper withBorder shadow="md" p={20} mt={"8vh"} radius="md">
            <div>{t("emailSent")}</div>
            <form
              className="mt-6"
              onSubmit={form.onSubmit(() =>
                formSubmit(form.getTransformedValues())
              )}
            >
              <InputLabel>{t("passwordRecoveryCodeLabel")}</InputLabel>
              <PinInput
                ariaLabel=""
                length={8}
                {...form.getInputProps("code")}
                inputMode="numeric"
              />
              {form.errors.code != null && (
                <div className="flex h-3">
                  <p className="text-[var(--mantine-color-error)] text-xs mt-2">
                    {form.errors.code}
                  </p>
                </div>
              )}
              <div className="flex flex-row w-full justify-between items-end relative">
                <PasswordInput
                  className="w-full"
                  label={t("newPasswordLabel")}
                  inputWrapperOrder={["label", "input", "error", "description"]}
                  autoComplete="new-password"
                  required
                  mt="md"
                  {...form.getInputProps("newPassword")}
                />

                <div className=" top-5 right-0 absolute justify-center m-[0 auto] overflow-hidden h-4 w-4 rounded-md">
                  <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                    <PasswordCriteriaHover password={form.values.newPassword} />
                  </div>
                </div>
              </div>
              <PasswordInput
                label={t("newPasswordReenterLabel")}
                required
                mt="md"
                {...form.getInputProps("newPasswordReenter")}
              />
              <Button fullWidth mt="xl" type="submit">
                {t("submit")}
              </Button>
            </form>
          </Paper>
        </Container>
      </div>
    </ScrollArea>
  );
}

export default ChangePasswordWithCode;
