import { MantineProvider, MantineThemeOverride } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Slide, ToastContainer, ToastContainerProps } from "react-toastify";
import Login from "src/pages/auth/login/Login";
import ActivateAccount from "./pages/auth/sign-up/ActivateAccount";
import SignUp from "./pages/auth/sign-up/SignUp";
import NotFound from "./pages/components/NotFound";
import "./pages/i18n/i18n";
import DeviceDetail from "./pages/in-app/device-list/device-detail/DeviceDetail";
import DeviceList from "./pages/in-app/device-list/DeviceList";
import GroupList from "./pages/in-app/group-list/GroupList";
import ActivityLogs from "./pages/in-app/log/activity/ActivityLogs";
import SensorLogs from "./pages/in-app/log/sensor/SensorLogs";
import SystemLogs from "./pages/in-app/log/system/SystemLogs";
import ManagedUserList from "./pages/in-app/managed-user-list/ManagedUserList";
import HeaderRoot from "./pages/in-app/nav/HeaderRoot/HeaderRoot";
import UserProfile from "./pages/in-app/user/UserProfile";
import initUserInterface from "./pages/init/InitUserInterface";
import TokenFilter from "./pages/utils/TokenFilter";
import { reduxStore } from "./services/redux/ReduxStore";
import ForgetPassword from "./pages/auth/forgetPassword/ForgetPassword";

initUserInterface();

const mantineTheme: MantineThemeOverride = {
  // fontSizes: {
  //   xs: rem(10),
  //   sm: rem(11),
  //   md: rem(14),
  //   lg: rem(16),
  //   xl: rem(20),
  // },
};

const toastContainerProps: ToastContainerProps = {
  position: "bottom-right",
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: true,
  rtl: false,
  closeButton: true,
  pauseOnFocusLoss: true,
  closeOnClick: true,
  // draggable: true,
  pauseOnHover: true,
  theme: "colored",
  transition: Slide,
  toastStyle: {
    fontSize: "1.09em",
    // "--toastify-color-warning": "#fab005",
    // "--toastify-color-info": "#3598bd",
    // "--toastify-color-success": "#18bb9c",
    "--toastify-color-warning": "#f39c11",
    // "--toastify-color-error": "#e84c3d",
  } as React.CSSProperties,
};

export const inAppPaths = {
  default: "/",
  devices: "/devices",
  members: "/members",
  groups: "/groups",
  deviceDetail: "/device/:deviceSn",
  logs: {
    activity: "/logs/activity",
    sensor: "/logs/sensor",
    system: "/logs/system",
  },
  userProfile: "/userProfile",
  all: "*",
} as const;

export const authPaths = {
  signUp: "/sign-up",
  login: "/login",
  activateAccount: "/activate-account",
  forgetPassword: "/forgetPassword",
};

export const paths = {
  authPaths: authPaths,
  inAppPaths: inAppPaths,
  notFound: "404",
} as const;

function App() {
  return (
    <>
      <BrowserRouter>
        <MantineProvider theme={mantineTheme}>
          <Provider store={reduxStore}>
            <Routes>
              <Route element={<HeaderRoot />}>
                <Route
                  element={<TokenFilter allowActivated to={authPaths.login} />}
                >
                  <Route path={inAppPaths.groups} element={<GroupList />} />
                  <Route
                    path={inAppPaths.devices}
                    element={<DeviceList />}
                    index
                  />
                  <Route
                    path={inAppPaths.members}
                    element={<ManagedUserList />}
                  />
                  <Route
                    path={inAppPaths.deviceDetail}
                    element={<DeviceDetail />}
                    index
                  />
                  <Route
                    path={inAppPaths.logs.activity}
                    element={<ActivityLogs />}
                  />
                  <Route
                    path={inAppPaths.logs.sensor}
                    element={<SensorLogs />}
                  />
                  <Route
                    path={inAppPaths.logs.system}
                    element={<SystemLogs />}
                  />
                  <Route
                    path={inAppPaths.userProfile}
                    element={<UserProfile />}
                  />
                  <Route path={inAppPaths.all} element={<NotFound />} />
                </Route>
                <Route
                  element={
                    <TokenFilter
                      allowNull
                      allowUnactivated
                      to={inAppPaths.default}
                    />
                  }
                >
                  <Route path={authPaths.login} element={<Login />} />
                  <Route path={authPaths.signUp} element={<SignUp />} />
                  <Route
                    path={authPaths.forgetPassword}
                    element={<ForgetPassword />}
                  />
                  <Route
                    element={
                      <TokenFilter allowUnactivated to={inAppPaths.default} />
                    }
                  >
                    <Route
                      path={authPaths.activateAccount}
                      element={<ActivateAccount />}
                    />
                  </Route>
                </Route>
              </Route>
              <Route
                path={inAppPaths.default}
                element={<Navigate to={inAppPaths.devices} replace />}
              />
              <Route path={paths.notFound} element={<NotFound />} />
            </Routes>
          </Provider>
        </MantineProvider>
      </BrowserRouter>
      <ToastContainer {...toastContainerProps} />
    </>
  );
}

export default App;
