import { reduxStore } from "../redux/ReduxStore";
import tokenSlice from "../redux/slices/UserSlice";
import localStorageService from "src/repositories/local-storage/LocalStorageService";
import initApi, { globalAxiosAction } from "src/repositories/api/Init.api";
import { addUnauthorizedInterceptor } from "src/repositories/api/Utils/Utils.api";
import UserInfo from "src/repositories/model/UserInfo";
import authService from "./auth/Auth.service";
import TokenInfo from "src/repositories/api/auth/model/TokenInfo";
import { selectedGroupSlice } from "../redux/slices/GroupSlice";

const initService = () => {
  initApi();
  setupToken();
  // setupGroup();
  // setupInvalidTokenInterceptor();
};

const setupToken = (): TokenInfo | null => {
  const tokenInfo = localStorageService.getData("tokenInfo");
  if (tokenInfo != null) {
    reduxStore.dispatch(tokenSlice.actions.update(tokenInfo));
  }
  return tokenInfo;
};

const setupGroup = (): Group | null => {
  const group = localStorageService.getData("defaultGroup");
  if (group != null) {
    reduxStore.dispatch(selectedGroupSlice.actions.update(group));
  }
  return group;
};

const setupInvalidTokenInterceptor = () => {
  globalAxiosAction.add((axios) =>
    addUnauthorizedInterceptor(axios, () => {
      authService.logout();
    })
  );
};

export default initService;
