import {
  Button,
  Container,
  Paper,
  PasswordInput,
  ScrollArea,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { paths } from "src/App";
import PasswordCriteriaHover from "src/pages/components/PasswordCriteriaHover";
import {
  nullToastUpdateOptions,
  toastCommonError,
} from "src/pages/utils/ToastUtils";
import { getPasswordError } from "src/pages/utils/ValidatorUtils";
import authService from "src/services/core/auth/Auth.service";
import { useAppSelector } from "src/services/redux/ReduxStore";

type ChangePasswordValues = {
  oldPassword: string;
  newPassword: string;
  newPasswordReenter: string;
};

const ChangePassword: (props: { onSuccess?: () => void }) => JSX.Element = (
  props
) => {
  // const wrongCurrentPassword = useRef<string>();
  const tokenPayload = useAppSelector((s) => s.token)?.payload;
  const { t } = useTranslation(undefined, {
    keyPrefix: "inApp.userProfile.changePassword",
  });

  if (tokenPayload == null || !tokenPayload.isAccountActivated) {
    return <Navigate to={paths.authPaths.login}></Navigate>;
  }

  const form = useForm<ChangePasswordValues>({
    initialValues: { oldPassword: "", newPassword: "", newPasswordReenter: "" },
    validate: {
      // oldPassword: (value) =>
      //   value == wrongCurrentPassword.current
      //     ? t("wrongCurrentPassword")
      //     : undefined,
      newPassword: (value, values) =>
        value == values.oldPassword
          ? t("newPasswordMustBeDifferent")
          : getPasswordError(value),
      newPasswordReenter: (value, values) =>
        value != values.newPassword ? t("passwordReenterNotMatch") : undefined,
    },
  });

  const formSubmit = useCallback(async (values: ChangePasswordValues) => {
    const promise = authService.changePassword(
      values.oldPassword,
      values.newPassword
    );
    const changePasswordId = toast.loading(t("changingPassword"), {
      autoClose: false,
      closeButton: true,
      closeOnClick: true,
    });
    const result = await promise;
    if (result.success) {
      toast.update(changePasswordId, {
        ...nullToastUpdateOptions,
        render: t("changePasswordSuccess"),
        type: "success",
      });
      props.onSuccess?.();

      return;
    }

    if (result.code == "wrong-password") {
      toast.update(changePasswordId, {
        ...nullToastUpdateOptions,
        render: t("wrongCurrentPassword"),
        type: "error",
      });
      // wrongCurrentPassword.current = form.values.oldPassword;
      form.reset();
      form.setFieldError("oldPassword", t("wrongCurrentPassword"));
      return;
    }

    toastCommonError(result.code, changePasswordId);
  }, []);

  return (
    <ScrollArea>
      <Container>
        <Paper my={"2vh"} radius="md">
          <Title className="!mb-10 text-left">{t("title")}</Title>
          <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
            <PasswordInput
              className="w-full"
              label={t("oldPasswordLabel")}
              autoComplete="old-password"
              required
              mt="md"
              {...form.getInputProps("oldPassword")}
            />
            <div className="flex flex-row w-full justify-between items-end relative">
              <PasswordInput
                className="w-full"
                label={t("newPasswordLabel")}
                inputWrapperOrder={["label", "input", "error", "description"]}
                autoComplete="new-password"
                required
                mt="md"
                {...form.getInputProps("newPassword")}
              />

              <div className=" top-5 right-0 absolute justify-center m-[0 auto] overflow-hidden h-4 w-4 rounded-md">
                <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                  <PasswordCriteriaHover password={form.values.newPassword} />
                </div>
              </div>
            </div>
            <PasswordInput
              label={t("newPasswordReenterLabel")}
              required
              mt="md"
              {...form.getInputProps("newPasswordReenter")}
            />
            <div>
              <Button mt="xl" type="submit">
                {t("submit")}
              </Button>
            </div>
          </form>
        </Paper>
      </Container>
    </ScrollArea>
  );
};
export default ChangePassword;
