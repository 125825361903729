import {
  createFailedResponse,
  createSuccessResponse,
  FailedResponse,
} from "../../model/FailableResponse";
import { globalAxiosAction } from "../Init.api";
import { createDefaultAxios } from "../Utils/AxiosUtility";
import {
  GeneralApiResponse,
  handleGeneralApiError,
} from "../Utils/ResponseUtility";
import { getBrearerTokenHeader } from "../Utils/Utils.api";
import TokenHolder from "./model/TokenHolder";

const axiosInstance = createDefaultAxios("/api/auth/", 8000);
globalAxiosAction.subscribe(axiosInstance);

async function login(
  email: string,
  password: string
): Promise<
  GeneralApiResponse<TokenHolder> | FailedResponse<"invalid-credentials">
> {
  try {
    const response = await axiosInstance.post<TokenHolder>("login", {
      email,
      password,
    });
    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e, (e, status, data) => {
      try {
        if (status == 401 && data.toLowerCase() == "invalid credentials") {
          return createFailedResponse("invalid-credentials" as const);
        }
      } catch (e) {
        undefined;
      }
      return null;
    });
  }
}

async function signUp(
  email: string,
  name: string,
  password: string
): Promise<
  | GeneralApiResponse<TokenHolder>
  | FailedResponse<"email-exists" | "invalid-email" | "invalid-password">
> {
  try {
    const response = await axiosInstance.post<TokenHolder>(
      "signUp",
      {
        email,
        name,
        password,
      },
      { timeout: 20000 }
    );
    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e, (e, status, data) => {
      if (status == 409 && data.code == "email-exists") {
        return createFailedResponse("email-exists");
      }

      if (status == 400) {
        switch (data.code) {
          case "invalid-password": {
            return createFailedResponse("invalid-password");
          }
          case "invalid-email": {
            return createFailedResponse("invalid-email");
          }
        }
      }
      return null;
    });
  }
}

async function activateAccount(
  activationCode: number,
  unactivatedToken: string
): Promise<
  GeneralApiResponse<TokenHolder> | FailedResponse<"incorrect-activation-code">
> {
  try {
    const response = await axiosInstance.post<TokenHolder>(
      "activateAccount",
      {
        activationCode,
      },
      { headers: { Authorization: `Bearer ${unactivatedToken}` } }
    );
    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e, (e, status, data) => {
      if (status == 400 && data.code == "incorrect-activation-code") {
        return createFailedResponse("incorrect-activation-code");
      }
      return null;
    });
  }
}

async function deleteAccount(): Promise<GeneralApiResponse> {
  try {
    await axiosInstance.delete("deleteAccount", getBrearerTokenHeader());
    return createSuccessResponse();
  } catch (e) {
    return handleGeneralApiError(e);
  }
}

async function changePassword(
  oldPassword: string,
  newPassword: string
): Promise<GeneralApiResponse | FailedResponse<"wrong-password">> {
  try {
    await axiosInstance.patch(
      "changePassword",
      {
        oldPassword,
        newPassword,
      },
      getBrearerTokenHeader()
    );
    return createSuccessResponse();
  } catch (e) {
    return handleGeneralApiError(e, (e, status, data) => {
      if (status == 400 && data.code == "wrong-password") {
        return createFailedResponse("wrong-password");
      }
      return null;
    });
  }
}

async function forgetPassword(
  email: string
): Promise<
  GeneralApiResponse | FailedResponse<"invalid-email" | "user-not-found">
> {
  try {
    await axiosInstance.post<TokenHolder>(
      "forgetPassword",
      {
        email,
      },
      { timeout: 20000 }
    );
    return createSuccessResponse();
  } catch (e) {
    return handleGeneralApiError(e, (e, status, data) => {
      if (status == 404 && data.code == "user-not-found") {
        return createFailedResponse("user-not-found");
      }
      if (status == 400 && data.code == "invalid-email") {
        return createFailedResponse("invalid-email");
      }
      return null;
    });
  }
}

async function setNewPasswordWithCode(
  email: string,
  code: number,
  newPassword: string
): Promise<
  | GeneralApiResponse
  | FailedResponse<
      | "invalid-email"
      | "user-not-found"
      | "wrong-reset-password-code"
      | "invalid-password"
    >
> {
  try {
    await axiosInstance.patch(
      "setNewPasswordWithCode",
      {
        email,
        code,
        newPassword,
      },
      getBrearerTokenHeader()
    );
    return createSuccessResponse();
  } catch (e) {
    return handleGeneralApiError(e, (e, status, data) => {
      // not guaranteed
      const code = data.code as string;
      if (
        (status == 400 && code == "invalid-email") ||
        (status == 400 && code == "invalid-password") ||
        (status == 401 && code == "wrong-reset-password-code") ||
        (status == 404 && code == "user-not-found")
      ) {
        return createFailedResponse(code);
      }
      return null;
    });
  }
}

export const authApi = {
  login,
  signUp,
  activateAccount,
  deleteAccount,
  changePassword,
  forgetPassword,
  setNewPasswordWithCode,
};
