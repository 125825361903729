import i18next from "i18next";

export const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

export const validatePassword = (password: string) => {
  return {
    isLength8: password.length >= 8,
    containsDigit: /\d/.test(password),
    containsUppercase: /\p{Lu}/u.test(password),
    containsLowercaes: /\p{Ll}/u.test(password),
    notContainsLineBreak: !/\n/.test(password),
  };
};

const prefix = "utils.validatorUtils.";

export const getPasswordError = (password: string): string | undefined => {
  const result = validatePassword(password.trim());
  if (!result.isLength8) {
    return i18next.t(prefix + "passwordError.notLongEnough");
  }
  if (!result.containsDigit) {
    return i18next.t(prefix + "passwordError.noNumber");
  }
  if (!result.containsLowercaes) {
    return i18next.t(prefix + "passwordError.noLowercase");
  }
  if (!result.containsUppercase) {
    return i18next.t(prefix + "passwordError.noUppercase");
  }
  if (!result.notContainsLineBreak) {
    return i18next.t(prefix + "passwordError.linebreak");
  }
};
